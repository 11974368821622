<template>
  <div class="login-page">
    <div class="login-error">
      <img
        class="icon"
        src="//img10.360buyimg.com/imgzone/jfs/t1/112118/1/36192/26273/64644508F6dad88f1/a27484a55ba13cf8.png"
        alt=""
      />
      <p class="description">
        对不起，当前登录已失效
        <br />
        请前往京麦服务市场重新登录
      </p>
      <el-button @click="handleBtnClick" class="btn">
        前往京麦服务市场重新登录 &gt;
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // 前往京麦服务市场按钮点击
    handleBtnClick() {
      window.location.href =
        'https://fw.jd.com/main/detail/FW_GOODS-1587601?p=fw&t=pcsearch';
      // window.open(
      //   'https://fw.jd.com/main/detail/FW_GOODS-1587601?p=fw&t=pcsearch',
      // );
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  .login-error {
    margin-top: 150px;
    text-align: center;
    .icon {
      display: inline-block;
      width: 462px;
      height: 258px;
    }

    .description {
      margin: 30px 0 40px;
      font-size: 26px;
      font-weight: 600;
    }

    .btn {
      background-color: #8080ff;
      color: #fff;
      font-size: 18px;
      border-radius: 12px;
    }
  }
}
</style>
