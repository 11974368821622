<template>
  <div class="container">
    <side-nav-component class="nav" />
    <div class="body">
      <el-header class="header-wrapper">
        <header-component />
        <breadcrumb-component />
      </el-header>
      <div class="main">
        <keep-alive>
          <router-view />
        </keep-alive>
        <wait-component v-show="wait === 1" />
      </div>
      <!-- <div class="main">
        <router-history :max="max" />
        <router-view />
      </div>
      <FileList :innerVisible="showFileList" @change="close" />-->
    </div>
    <right-float-component />
  </div>
</template>

<script>
// @ is an alias to /src
import headerComponent from '@/components/header/HeaderComponent';
import sideNavComponent from '@/components/sideNav/sideNavComponent';
import breadcrumbComponent from '@/components/breadcrumb/breadcrumbComponet';
import rightFloatComponent from '@/components/activityCommon/rightFloatComponent';
import waitComponent from '@/components/manageCommon/waitComponent';
// import common from '@/util/common';
import { mapGetters } from 'vuex';

export default {
  name: 'home',
  components: {
    headerComponent,
    sideNavComponent,
    breadcrumbComponent,
    rightFloatComponent,
    waitComponent,
  },
  computed: {
    ...mapGetters('data', ['wait']),
  },
  watch: {},
  data() {
    return {
      // venderId: '12743066',
      venderId: '1000310642',
      prodBToken: '',
      initSideNav: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  // max-width: 1920px;
  // margin: 0 auto;
  min-height: 100vh;
  background: $lightgray;
  display: flex;
  color: $blueblack;

  .body {
    position: relative;
    width: nvw(1920 - 300);
    margin-left: nvw(300);
  }

  .header-wrapper {
    position: sticky;
    top: 0;
    width: 96%;
    height: auto !important;
    margin-left: 4%;
    padding: 0;
    padding-bottom: vw(45);
    background: $lightgray;
    z-index: 9;
  }

  .main {
    width: nvw((1920 - 300) / 100 * 92);
    margin-left: 4%;
  }
}
</style>
