const clearStoreData = function () {
  window.localStorage.clear();
};

const setStoreData = function (key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const getStoreData = function (key) {
  let storeStr = window.localStorage.getItem(key);
  if (storeStr) {
    try {
      return JSON.parse(storeStr);
    } catch (e) {
      console.error(e);
    }
  } else {
    return null;
  }
};

const removeStoreData = function (key) {
  window.localStorage.removeItem(key);
};

function isVehicleNumber(vehicleNumber) {
  const xreg =
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DABCEFGHJK]$)|([DABCEFGHJK][A-HJ-NP-Z0-9][0-9]{4}$))/;
  const creg =
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
  if (vehicleNumber.length == 7) {
    return creg.test(vehicleNumber);
  } else if (vehicleNumber.length == 8) {
    return xreg.test(vehicleNumber);
  } else {
    return false;
  }
}

function checkIdcard(idcard = '') {
  const Errors = [
    '',
    '身份证号码位数不对',
    '身份证号码出生日期超出范围或含有非法字符',
    '身份证号码校验错误',
    '身份证地区非法',
  ];
  const area = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外',
  };

  let Y, JYM;
  let S, M, ereg;
  let idcard_array = [];
  idcard_array = idcard.split('');
  //地区检验
  if (area[parseInt(idcard.substr(0, 2))] == null) return Errors[4];
  //身份号码位数及格式检验
  switch (idcard.length) {
    case 15:
      if (
        (parseInt(idcard.substr(6, 2)) + 1900) % 4 === 0 ||
        ((parseInt(idcard.substr(6, 2)) + 1900) % 100 === 0 &&
          (parseInt(idcard.substr(6, 2)) + 1900) % 4 === 0)
      ) {
        ereg =
          /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/; //测试出生日期的合法性
      } else {
        ereg =
          /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/; //测试出生日期的合法性
      }
      if (ereg.test(idcard)) return Errors[0];
      else return Errors[2];
    case 18:
      //18位身份号码检测
      //出生日期的合法性检查
      //闰年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))
      //平年月日:((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))
      if (
        parseInt(idcard.substr(6, 4)) % 4 === 0 ||
        (parseInt(idcard.substr(6, 4)) % 100 === 0 &&
          parseInt(idcard.substr(6, 4)) % 4 === 0)
      ) {
        ereg =
          /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式
      } else {
        ereg =
          /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式
      }
      if (ereg.test(idcard)) {
        //测试出生日期的合法性
        //计算校验位
        S =
          (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 +
          (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 +
          (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 +
          (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 +
          (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 +
          (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 +
          (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 +
          parseInt(idcard_array[7]) * 1 +
          parseInt(idcard_array[8]) * 6 +
          parseInt(idcard_array[9]) * 3;
        Y = S % 11;
        JYM = '10X98765432';
        M = JYM.substr(Y, 1); //判断校验位
        if (M === idcard_array[17]) return Errors[0]; //检测ID的校验位
        else return Errors[3];
      } else return Errors[2];
    default:
      return Errors[1];
  }
}

function checkCompany(val, checkEnable = false) {
  // 前置条件即校验规则的第1至5要求
  const pattern = /^([1-9ANY])([1-9])([0-9]{6})([0-9A-HJ-NP-RT-UW-Y]{10})$/;
  if (!pattern.test(val) || !checkEnable) return pattern.test(val);
  // 定义求模基数31
  const MODE_BASE = 31;
  // 空字符串
  const EMPTY_STR = '';
  // 代码字符数组, 其下标作为代码字符数值
  const CHAR_CODES = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    'M',
    'N',
    'P',
    'Q',
    'R',
    'T',
    'U',
    'W',
    'X',
    'Y',
  ];
  // 各位置序号上的加权因子
  const WEIGHT_FACTORS = [
    1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28,
  ];
  // 初始化一个与信用代码等长的空的代码字符数值数组
  // 用于和加权因子进行乘积求和运算
  let values = new Array(val.length);
  // 初始化求和值
  let sum = 0;
  // 将信用代码字符串转换为代码字符数值数组
  val
    .split(EMPTY_STR)
    .forEach(
      (item, index) =>
        (values[index] = CHAR_CODES.findIndex((elem) => elem === item)),
    );
  // 删除最后一位元素(第18位不参与求和计算)
  const endValue = values.pop();
  // 将字符数组数值逐个元素与加权因子乘积求和
  WEIGHT_FACTORS.forEach((item, index) => (sum += item * values[index]));
  // 换算校验代码字符
  // let checkCode = CHAR_CODES[MODE_BASE - sum % MODE_BASE];
  // return val.substring(17, 18) === checkCode;
  // 不换算回字符了，直接用第18位的数值进行比较
  return endValue === MODE_BASE - (sum % MODE_BASE);
}

function isIE() {
  if (
    !!window.ActiveXObject ||
    'ActiveXObject' in window ||
    window.navigator.userAgent.indexOf('MSIE') >= 1
  )
    return true;
  else return false;
}

// 导出表格
function exportExcel(url, fileName) {
  fetch(url).then((res) => {
    res.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName + '.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  });
}

/**
 * 时间选择器的统一处理时间函数
 * @param {*} time  时间
 * @param {*} single single标识符,拼接年月日的连接符,默认为-
 * @param {*} isHMS isHMS是否带有时分秒
 */
function filterTime(time, single = '-', isHMS = false) {
  var date = new Date(time);
  var y = date.getFullYear();
  var m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  var d = date.getDate();
  d = d < 10 ? '0' + d : d;
  var h = date.getHours();
  h = h < 10 ? '0' + h : h;
  var minute = date.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;
  var s = date.getSeconds();
  s = s < 10 ? '0' + s : s;
  if (isHMS) {
    return y + single + m + single + d + ' ' + h + ':' + minute + ':' + s;
  } else {
    return y + single + m + single + d;
  }
}

// 获取前一天的数据
function beforeDay() {
  let time = new Date();
  let beforeTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const year = beforeTime.getFullYear();
  let month = beforeTime.getMonth() + 1;
  let day = beforeTime.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  const NOW_MONTHS_AGO = `${year}-${month}-${day} 00:00:00`;
  return NOW_MONTHS_AGO;
}

//获取当前时间 年月日
function getNowDate(type = 'yms', time = '') {
  let timeOne;
  if (time) {
    timeOne = new Date(time);
  } else {
    timeOne = new Date();
  }
  const year = timeOne.getFullYear();
  let month = timeOne.getMonth() + 1;
  let day = timeOne.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;
  if (type == 'yms') {
    const NOW_MONTHS_AGO = `${year}年${month}月${day}日`;
    return NOW_MONTHS_AGO;
  } else {
    const NOW_MONTHS_AGO = `${year}-${month}-${day} 00:00:00`;
    return NOW_MONTHS_AGO;
  }
}

export default {
  clearStoreData,
  setStoreData,
  getStoreData,
  removeStoreData,
  checkIdcard,
  checkCompany,
  isIE,
  exportExcel,
  filterTime,
  beforeDay,
  getNowDate,
};

export { isVehicleNumber };
