<template>
  <el-badge is-dot :value="count" :hidden="count < 1" class="self-badge">
    <span @click="dialogMessageVisible = true" class="el-mess">
      <i class="el-icon-bell"></i>
      <span class="el-notice-desc">通知</span>
    </span>
    <message-dialog-component
      v-if="dialogMessageVisible"
      :status.sync="dialogMessageVisible"
    />
  </el-badge>
</template>
<script>
import messageDialogComponent from './messageDialogComponent';
import { getSystemNoticeList } from '@/api/b/common.api';

export default {
  name: 'messageComponent',
  components: {
    messageDialogComponent,
  },
  computed: {},
  data() {
    return {
      count: 0,
      dialogMessageVisible: false,
      params: {
        type: 1,
        isRead: 0,
        pageNo: 1,
        pageSize: 10,
      },
      final: false,
    };
  },
  created() {
    // this.timer = setInterval(() => this.getUnreadCount(), 5000)
    this.getUnreadCount(this.params);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getUnreadCount(params) {
      getSystemNoticeList(
        params.type,
        params.isRead,
        params.pageNo,
        params.pageSize,
      ).then((response) => {
        if (response.code == 200) {
          const length = response.data.list.length;
          if (length > 0) this.count = length;
          else {
            if (!this.final) {
              this.final = true;
              this.$set(this.params, 'type', 2);
              this.getUnreadCount(this.params);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-mess {
  display: flex;
  width: vw(75);
  align-items: center;
  cursor: pointer;
}

.el-icon-bell {
  display: inline-block;
  width: vw(23);
  height: vw(27);
  background-image: url('https://img10.360buyimg.com/imgzone/jfs/t1/181796/25/30120/525/63875e28E84bac01b/b468612277357de3.png');
  background-size: 100% 100%;

  &::before {
    content: none;
  }
}

.el-notice-desc {
  font-size: vw(18);
  margin-left: vw(6);
}

.self-badge {
  ::v-deep .el-badge__content.is-dot {
    width: vw(8);
    height: vw(8);
    background: red;

    &.is-fixed {
      right: auto;
      left: vw(13);
      transform: none;
    }
  }
}
</style>
