<template>
  <!-- 一级菜单 -->
  <el-menu-item :index="data.menuLink">
    <span slot="title">{{ data.title }}</span>
  </el-menu-item>
</template>
<script>
import common from '@/util/common';
import { mapGetters } from 'vuex';

export default {
  name: 'headerNavMenuItem',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('data', ['activeIndex']),
    // activeIndex() {
    //   return common.getStoreData('activeMenu')?.menuLink;
    // },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    changeActiveIndex(index) {
      common.setStoreData('activeIndex', index);
      this.$store.commit('data/handleChangeActiveIndex', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu-item {
  width: nvw(158);
  text-align: center;
  padding: 0 0 nvw(15);
  border-bottom: nvw(4) solid $blueblack !important;
  cursor: pointer;
  height: auto !important;
  line-height: 1 !important;
  color: $blueblack !important;
  margin-right: vw(58) !important;
  float: none !important;

  span {
    display: inline-block;
    font-size: nvw(20);
  }

  &.is-active {
    color: $purple !important;
    border-color: $purple !important;
  }

  &:focus,
  &:hover {
    background: transparent !important;
  }
}
</style>
