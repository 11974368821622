import { COMMON_MENUS } from '../mutations-type';

const state = {
  menus: [],
};

const getters = {
  menus: (state) => state.menus,
};

const mutations = {
  [COMMON_MENUS](state, payload) {
    state.menus = payload;
  },
};

const actions = {
  async setMenus({ commit }, payload) {
    commit(COMMON_MENUS, payload);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
