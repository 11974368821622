const common = {
  // 获取商家信息
  getShopInfo: '/api-b/user/getUserInfo',
  // 获取生效菜单列表
  getVaildList: '/api-b/menu/getVaildList',
  // 保存菜单访问日志
  saveLog: '/api-b/menuAccessDetailLog/saveLog',
  // 查询系统通知列表
  getSystemNoticeList: '/api-b/notice/getNoticeList',
  // 商家点击通知接口
  saveNoticeStatus: '/api-b/noticeUserLog/save',
  // 保存反馈信息
  saveFeedback: '/api-b/feedback/save',
  // 上传图片（文件流）
  uploadFileByIO: '/api-b/common/tools/uploadFileByIO',
  // 一键补齐资料
  updateBasicUserInfo: '/api-b/user/updateBasicUserInfo',
  // 创建-直发型优惠券(POP)
  createPopCoupon: '/api-b/couponInfo/createPopCoupon',
  // 创建-直发型优惠券(自营）
  createSoCoupon: '/api-b/couponInfo/createSoCoupon',
  // 优惠券绑定活动
  couponBindActivity: '/api-b/couponInfo/couponBindActivity',

  /*
    活动中心
  */
  // 根据月份查询营销节日列表
  getCalendarInfo: '/api-b/marketing/getCalender',
  // 查询活动模板
  getTemplateList: '/api-b/activityTemplate/getTemplateList',
  // 查询banner广告
  getBannerInfo: '/api-b/adSetting/getBannerInfos',
  // 查询公告列表
  getNoticeInfo: '/api-b/adSetting/getNoticeInfos',

  /*
    管理中心
  */
  // 查询会员明细
  getNoticeList: '/api-b/notice/getNoticeList',
  // 获取兑换版本关系接口
  getVersionConfig: '/api-b/jiaomaBeansVersionConfig/getVersionConfig',
  // 查询京豆可用余额
  getAccountInfo: '/api-b/beansPlan/getAccountInfo',
  // 查询京豆计划列表（软件内）
  getBeansPlanList: '/api-b/beansPlan/list',
  // 查询京豆发放记录（软件内）
  getBeanLogPage: '/api-b/beansSendLog/getBeanLogPage',
  // 创建京豆计划
  saveBeansPlan: '/api-b/beansPlan/savePlan',
  // 兑换
  exchange: '/api-b/jiaomaBeansExchangeVersion/exchange',
  // 获取POP店铺sku列表
  getPopSkuList: '/api-b/common/sku/getPopSkuList',
  // 获取自营店铺sku列表
  getSoSkuList: '/api-b/common/sku/getSoSkuList',
  // 自营-查询putkey信息
  getSOPutkeyInfo: '/api-b/couponInfo/getSOPutkeyInfo',
  // 查询活动列表
  entityListData: '/api-b/activityInfoEntity/listData',
  // 查询草稿活动列表
  draftsListData: '/api-b/activityDrafts/listData',
  // 修改活动状态
  updateStatue: '/api-b/activityInfoEntity/updateStatue',
  // 查询活动信息
  getActivityInfo: '/api-b/activityInfoEntity/getActivityInfo',

  /*
    创建活动
  */
  // 创建上新盲盒抽奖活动（草稿箱）接口
  blindBox: '/api-b/blindBox/draw/save',
  // 修改上新盲盒抽奖活动（草稿箱）接口
  editBlindBox: '/api-b/blindBox/draw/edit',
  // 保存爆品商品/新品上新
  saveSkus: '/api-b/wxActShowSkus/saveSkus',

  /*
    品牌联合
  */
  // 联合大厅查询列表接口
  getBusInfoList: '/api-b/unionBusInfo/getBusInfoList',
  // 查看公交车
  getActivityBusInfo: '/api-b/unionBusInfo/draw/getActivityInfo',
  // 请求上车
  applyJoin: '/api-b/unionBusInfo/applyJoin',
  // 直接上车
  joinBus: '/api-b/unionBusInfo/joinBus',
  // 等车专区 / 查看用户资料
  getWaitAreaInfo: '/api-b/unionBasicUserInfo/selectInfo',
  // 保存草稿箱/发车
  saveDraft: '/api-b/unionBusInfo/draw/save',
  // 等车专区 / 查询用户可用于邀请的公交列表
  getBusSimpleInfoList: '/api-b/unionBusInfo/getBusSimpleInfoList',
  // 等车专区 / 点击查询用户详细资料
  selectVenderInfo: 'api-b/unionBasicUserInfo/selectVenderInfo',
  // 等车专区 / 邀请用户上车
  inviteJoin: 'api-b/unionBasicUserInfo/inviteJoin',
  // 我的公交 / 当前公交
  myBus: 'api-b/unionBusInfo/myBus',
  // 我的公交 / 公交邀请 同意公交
  inviteOrAgree: 'api-b/unionBusInfo/inviteOrAgree',
  // 我的公交 / 请求上我的车
  applyMyCar: 'api-b/unionBusInfo/applyMyCar',
  // 删除公交车
  deleteBus: '/api-b/unionBusInfo/deleteBus',
};

export { common };
