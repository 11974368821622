import common from '@/util/common';
import { ACCESS_LOGIN, ACCESS_LOGOUT } from '../mutations-type';

const state = {
  userInfo: common.getStoreData('userinfo') ?? {},
  token: common.getStoreData('prodBToken') ?? '',
  menuPermissions: common.getStoreData('menuPermissions') ?? [],
  btnPermission: common.getStoreData('btnPermission') ?? [],
};

const getters = {
  userInfo: (state) => state.userInfo,
  token: (state) => state.token,
  menuPermissions: (state) => state.menuPermissions,
  btnPermission: (state) => state.btnPermission,
};

const mutations = {
  [ACCESS_LOGIN](state, payload) {
    state.userInfo = payload.userInfo;
    state.token = payload.token;
    state.menuPermissions = payload.menuPermissions;
    state.btnPermission = payload.btnPermission;
  },
  [ACCESS_LOGOUT]: (state) => {
    state.userInfo = {};
    state.token = '';
    state.menuPermissions = '';
    state.btnPermission = '';
    common.clearStoreData();
  },
};

const actions = {
  async login({ commit }, payload) {
    commit(ACCESS_LOGIN, payload);
  },
  async logout({ commit }) {
    commit(ACCESS_LOGOUT);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
