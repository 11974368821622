<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    // if (this.$router.name !== 'home') {
    //   this.$router.push({ name: 'home' });
    // }
  },
};
</script>
