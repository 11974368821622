import { saveLog } from '@/api/b/common.api';
import store from '@/store';
import common from '@/util/common';
import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
import home from '../views/Home.vue';
import login from '../views/Login.vue';
import loginTimeOut from '../views/errorPage/loginTimeOut.vue';

Vue.use(VueRouter);

const children = [
  {
    path: '/activityCenter/marketing/calendar',
    src: '/activityCenter/Calendar',
  },
  { path: '/activityCenter/drawActivity', src: '/activityCenter/DrawActivity' },
  {
    path: '/activityCenter/fissionDrainage',
    src: '/activityCenter/FissionDrainage',
  },
  {
    path: '/activityCenter/commercialPromotion',
    src: '/activityCenter/CommercialPromotion',
  },
  {
    path: '/activityCenter/promotionalActivity',
    src: '/activityCenter/PromotionalActivity',
  },
  { path: '/activityCenter/voteActivity', src: '/activityCenter/VoteActivity' },
  { path: '/activityCenter/signActivity', src: '/activityCenter/SignActivity' },
  { path: '/manageCenter/myActivity', src: '/manageCenter/Myactivity' },
  {
    path: '/manageCenter/activityInfo',
    src: '/manageCenter/activityInfo/ActivityInfo',
  },
  {
    path: '/manageCenter/releaseMaterial',
    src: '/manageCenter/ReleaseMaterial',
  },
  { path: '/manageCenter/floatingIcon', src: '/manageCenter/FloatingIcon' },
  { path: '/manageCenter/dataAnalysis', src: '/manageCenter/DataAnalysis' },
  { path: '/manageCenter/prizeCenter', src: '/manageCenter/PrizeCenter' },
  { path: '/manageCenter/topUpCenter', src: '/manageCenter/TopUpCenter' },
  { path: '/marketingPlan/helper', src: '/marketingPlan/Helper' },
  { path: '/marketingPlan/index', src: '/marketingPlan/Index' },
  { path: '/unionShops/hall', src: '/unionShops/hall/index' },
  { path: '/unionShops/startArea', src: '/unionShops/startArea/index' },
  { path: '/unionShops/waitArea', src: '/unionShops/waitArea/index' },
  { path: '/unionShops/myArea', src: '/unionShops/myArea/index' },
  { path: '/unionShops/activityData', src: '/unionShops/activityData/index' },
  { path: '/unionShops/unionArea', src: '/unionShops/UnionArea' },
  { path: '/help/index', src: '/help/Index' },
];

const childrenRoute = children.map((route) => {
  if (!route.component) {
    route = {
      ...route,
      component: () =>
        import(/* webpackChunkName: "[request]" */ `@/views${route.src}.vue`),
    };
  }
  return route;
});

const routes = [
  {
    path: '/',
    redirect: '/mockUser',
  },
  {
    path: '/mockUser',
    name: 'login',
    component: login,
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    redirect: '/activityCenter/marketing/calendar',
    children: childrenRoute,
  },
  {
    path: '/loginTimeOut',
    name: 'loginTimeOut',
    component: loginTimeOut,
  },
  // {
  //   path: '/home/activityCenter/calendar',
  //   name: 'calendar',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/home/activityCenter/Calendar'),
  //   // children: childrenRoute,
  // },
];

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  routes,
});

const shopInfo = common.getStoreData('shopInfo');
const venderId = shopInfo?.venderId;
const nick = shopInfo?.pin ?? '';
const menuPermissions = common.getStoreData('menuPermissions');
const all = _.flatten(_.map(menuPermissions, 'children'));

//路由守卫
router.beforeEach((to, from, next) => {
  const token = common.getStoreData('prodBToken');
  if (to.matched.length === 0) {
    next({ name: 'login' });
  }

  if (to.name !== 'login' && to.name !== 'loginTimeOut') {
    const activeMenu = _.filter(all, ['menuLink', to.path])[0];
    const filterMenu = _.filter(menuPermissions, (item) => {
      const index = _.indexOf(
        _.map(item.children, ['menuLink', to.path]),
        true,
      );
      if (index > -1) return item;
    })[0];
    const activeIndex = filterMenu?.index;
    common.setStoreData('activeIndex', activeIndex);
    store.commit('data/handleChangeActiveIndex', activeIndex);
    if (activeMenu?.menuStatue === 2) {
      common.setStoreData('wait', 1);
      store.commit('data/handleChangeWait', 1);
    } else {
      common.setStoreData('wait', 0);
      store.commit('data/handleChangeWait', 0);
    }
  }
  if (to.name !== 'login' && to.name !== 'loginTimeOut' && !token)
    next({ name: 'loginTimeOut' });
  else next();
});

router.afterEach((to, from) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant',
  });
  if (to.name === 'login') return;
  const activeMenu = _.filter(all, ['menuLink', to.path])[0];
  common.setStoreData('activeMenu', activeMenu);
  store.commit('data/handleChangeActiveMenu', activeMenu);
  store.commit('data/handleChangeMenuName', activeMenu?.menuName);
  store.commit('data/handleChangeMainMenuName', activeMenu?.mainMenuName);
  const menuCode = activeMenu?.menuCode;
  // store.dispatch('user/getBeansInfo');
  // store.dispatch('user/getShopInfo');

  if (!menuCode) return;
  if (from.name !== 'login') {
    const params = {
      venderId: venderId,
      menuCode: menuCode,
      menuName: activeMenu?.mainMenuName,
      nick: nick,
      sonNick: '',
      accessRefer: location.href,
    };
    let formData = new FormData();
    _.toPairs(params).forEach((item) => {
      formData.append(item[0], item[1]);
    });
    saveLog(formData).then();
  }
});

export default router;
