<template>
  <div class="rightFloatComponent-container">
    <button @click="dialogConsultVisible = true">
      <img
        src="https://img10.360buyimg.com/imgzone/jfs/t1/75752/26/23136/963/639e8604E1c578500/240ef31589225e4a.png"
        alt
        class="icon1"
      />
      <span>咨询</span>
    </button>
    <div class="line"></div>
    <button @click="dialogFeedbackVisible = true">
      <img
        src="https://img10.360buyimg.com/imgzone/jfs/t1/144934/21/32274/652/639e8604E0849b94f/33ecd5c13ac70ec6.png"
        alt
        class="icon2"
      />
      <span>反馈</span>
    </button>
    <consult-dialog-component :status.sync="dialogConsultVisible" />
    <feedback-dialog-component :status.sync="dialogFeedbackVisible" />
  </div>
</template>

<script>
import consultDialogComponent from './consultDialogComponent';
import feedbackDialogComponent from './feedbackDialogComponent';

export default {
  name: 'rightFloatComponent',
  components: {
    consultDialogComponent,
    feedbackDialogComponent,
  },
  data() {
    return {
      dialogConsultVisible: false,
      dialogFeedbackVisible: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.rightFloatComponent-container {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: vw(66);
  padding: vw(20) 0;
  background: #fff;
  border-radius: vw(12);
  box-shadow: 0 0 2px $darkgray;
  z-index: 10;
}

button {
  text-align: center;
  display: block;
  width: 100%;

  .icon1 {
    width: vw(33);
    margin: 0 auto;
  }

  .icon2 {
    width: vw(29);
    margin: 0 auto;
  }

  span {
    display: block;
    font-size: vw(14);
    margin-top: vw(6);
  }
}

.line {
  width: 100%;
  height: 0.5px;
  background: #c1c1c9;
  margin: vw(12) 0;
}
</style>
