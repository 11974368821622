<template>
  <div class="header">
    <header-nav-component />
    <div class="header-menu"></div>
    <div class="user-center">
      <button class="header-tip">操作提示</button>
      <div class="avatar">
        <message-component />
        <el-dropdown>
          <div class="wrapper">
            <el-avatar :src="headerUrl"></el-avatar>
            <span class="el-dropdown-link" :title="shopName">
              {{ shopName }}
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="dialogUserInfoVisible = true">
              查看资料编辑
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-avatar :src="headerUrl"></el-avatar>
        <p class="shopName">{{ shopName }}</p>-->
      </div>
    </div>
    <user-info-dialog-component
      v-if="dialogUserInfoVisible"
      :status.sync="dialogUserInfoVisible"
    />
  </div>
</template>

<script>
import common from '@/util/common';
import { mapActions } from 'vuex';
import messageComponent from './user/messageComponent';
import userInfoDialogComponent from './user/userInfoDialogComponent';
import headerNavComponent from './nav/headerNavComponent';
import { logout } from '@/api/b/login.api';

export default {
  name: 'headerComponent',
  components: { messageComponent, headerNavComponent, userInfoDialogComponent },
  computed: {
    headerUrl: function () {
      return common.getStoreData('shopInfo')?.shopLogo ?? this.defaultLogo;
    },
    shopName: function () {
      return common.getStoreData('shopInfo')?.shopName;
    },
  },
  data() {
    return {
      defaultLogo: '',
      dialogUserInfoVisible: false,
    };
  },
  created() {},
  methods: {
    ...mapActions('access', ['logout']),
    clickMenu(e) {
      if (e === 'logout') {
        logout().then(() => {
          this.logout();
          this.$router.push('/login');
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: nvw(100);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #d7d8dd;

  .header-tip {
    width: nvw(150);
    line-height: 44px;
    text-align: center;
    background: #fff;
    border-radius: nvw(12);
    color: $darkgray;
    border: 1px solid $darkgray;
    cursor: pointer;
    font-size: nvw(18);
  }

  .user-center {
    display: flex;
    align-items: center;
    padding-right: nvw(60);
    height: 100%;
  }

  .avatar {
    display: flex;
    align-items: center;
    margin-left: nvw(35);

    .el-avatar {
      flex-shrink: 0;
      width: nvw(34);
      height: nvw(34);
      margin-right: nvw(10);
      border-radius: 100%;
      background: $purple;
      display: inline-block;
    }

    .el-dropdown {
      margin-left: nvw(25);

      &:hover {
        cursor: pointer;
      }

      .wrapper {
        display: flex;
        align-items: center;
      }
    }

    .el-dropdown-link {
      width: nvw(90);
      font-size: nvw(20);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .shopName {
    max-width: nvw(150);
    font-size: nvw(20);
    margin-left: nvw(8);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.el-dropdown-menu {
  padding: 0;
}

::v-deep .el-dropdown-menu__item {
  font-size: nvw(20);
  line-height: 1;
  padding: nvw(20) nvw(40);
}
</style>
