<template>
  <el-breadcrumb separator="/">
    当前位置:
    <el-breadcrumb-item>{{ mainMenuName }}</el-breadcrumb-item>
    <el-breadcrumb-item>{{ menuName }}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
// import common from '@/util/common';
import { mapGetters } from 'vuex';

export default {
  name: 'breadcrumbComponent',
  computed: {
    ...mapGetters('data', ['activeIndex', 'menuName', 'mainMenuName']),
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-breadcrumb {
  margin-top: nvw(30);
  font-size: nvw(20);
}

::v-deep .el-breadcrumb__separator {
  color: $blueblack;
}

.el-breadcrumb__item {
  float: none;

  ::v-deep &:first-of-type {
    .el-breadcrumb__inner {
      color: #a2a2a2;
    }

    .el-breadcrumb__separator {
      margin: 0 vw(5);
    }
  }

  ::v-deep &:last-of-type {
    .el-breadcrumb__inner {
      color: $blueblack !important;
      font-weight: normal;
    }

    .el-breadcrumb__separator {
      display: none;
    }
  }
}
</style>
