import request from '@/util/http';
import { common } from '../urls/common';

// 获取商家信息
export function getShopInfo() {
  return request.GET(common.getShopInfo);
}

// 获取生效菜单列表
export function getVaildList() {
  return request.GET(common.getVaildList);
}
