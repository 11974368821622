<template>
  <div class="waitComponent-container">
    <img
      src="https://img10.360buyimg.com/imgzone/jfs/t1/193111/26/31562/135652/63e79f61F7a9fc0d1/410ee39d4a9c9910.jpg"
      alt
    />
  </div>
</template>

<script>
// import common from '@/util/common';
// import _ from 'lodash';

export default {
  name: 'waitComponent',
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.waitComponent-container {
  margin-top: vw(60);
}
</style>
