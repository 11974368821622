<template>
  <el-menu
    router
    popper-append-to-body="true"
    mode="horizontal"
    :default-active="activePath"
    key="header"
  >
    <!--将子菜单抽取出来，做为一个子组件-->
    <header-nav-menu-item-component
      v-for="(item, index) in menus"
      :key="index"
      :data="item"
    />
  </el-menu>
</template>

<script>
import headerNavMenuItemComponent from './headerNavMenuItemComponent';
import common from '@/util/common';
import { mapGetters } from 'vuex';

export default {
  name: 'headerNavComponent',
  components: {
    headerNavMenuItemComponent,
  },
  computed: {
    ...mapGetters('data', ['activeIndex']),
    // ...mapGetters('access', ['menuPermissions']),
    // ...mapGetters('common', ['menus']),
    menus() {
      return (
        common.getStoreData('menuPermissions') ?? this.$store.state.common.menus
      );
    },
    activePath() {
      return this.menus[this.activeIndex]?.menuLink;
    },
  },
  watch: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-menu {
  // width: vw(1020);
  display: flex;
  align-items: flex-end;
  background: transparent;
  border-bottom: none !important;
}
</style>
