import request from '@/util/http';
import { login } from '../urls/login';

// 商家登录
export function signIn(id) {
  return request.GET(login.loginUrl + '?venderId=' + id);
}
//登出
export function logout(data) {
  return request.POST(login.logoutUrl, data);
}
