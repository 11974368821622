import request from '@/util/http';
import { common } from '../urls/common';

// 查询会员明细
export function getNoticeList() {
  return request.GET(common.getNoticeList);
}

// 获取兑换版本关系接口
export function getVersionConfig() {
  return request.GET(common.getVersionConfig);
}

// 查询京豆可用余额
export function getAccountInfo() {
  return request.GET(common.getAccountInfo);
}

// 查询京豆计划列表（软件内）
export function getBeansPlanList(pageNo, pageSize) {
  return request.GET(
    common.getBeansPlanList + '?pageNo=' + pageNo + '&pageSize=' + pageSize,
  );
}

// 查询京豆发放记录（软件内）
export function getBeanLogPage(params) {
  return request.POST(common.getBeanLogPage, params);
}

// 创建京豆计划
export function saveBeansPlan(params) {
  return request.POST(common.saveBeansPlan, params);
}

// 兑换
export function exchange(params) {
  return request.POST(common.exchange, params);
}

// 获取POP店铺sku列表
export function getPopSkuList(params) {
  return request.POST(common.getPopSkuList, params);
}

// 获取自营店铺sku列表
export function getSoSkuList(params) {
  return request.POST(common.getSoSkuList, params);
}

// 自营-查询putkey信息
export function getSOPutkeyInfo(params) {
  return request.POST(common.getSOPutkeyInfo, params);
}

// 查询活动列表
export function entityListData(params) {
  return request.POST(common.entityListData, params);
}

// 查询草稿活动列表
export function draftsListData(params) {
  return request.POST(common.draftsListData, params);
}

// 修改活动状态
export function updateStatue(params) {
  return request.POST(common.updateStatue, params);
}

// 查询活动信息
export function getActivityInfo(activityId) {
  return request.GET(common.getActivityInfo + '?activityId=' + activityId);
}
