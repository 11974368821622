import ElementUI from 'element-ui';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as echarts from 'echarts';
import moment from 'moment';

import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/swiper.min.css';
import './assets/css/common.scss';
import './assets/css/element-ui.scss';
import '@/assets/css/index.scss';

import RightToolbar from '@/components/union/rightToolbar.vue';
import Pagination from '@/components/Pagination/index.vue';

Vue.component('RightToolbar', RightToolbar);
Vue.component('Pagination', Pagination);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
