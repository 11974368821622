<template>
  <!-- 一级菜单 -->
  <el-menu-item :index="data.menuLink">
    <i></i>
    <span slot="title">{{ data.menuName }}</span>
  </el-menu-item>
</template>
<script>
export default {
  name: 'navMenuItemComponent',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.el-menu-item {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  padding: vw(15) 0;
  padding-left: 0 !important;
  margin-bottom: vw(10);
  opacity: 0.4;
  cursor: pointer;
  color: #fff;
  height: auto;
  line-height: 1;

  i {
    display: inline-block;
    width: nvw(26);
    height: nvw(27);
    background-image: url('https://img10.360buyimg.com/imgzone/jfs/t1/162908/39/29747/550/63875e28Ee6cd9a04/824afe507633d8b2.png');
    background-size: 100% 100%;
    margin: 0 nvw(17) 0 nvw(70);
    opacity: 0;
  }

  span {
    display: inline-block;
    font-size: nvw(22);
    line-height: 1.5;
  }

  &.is-active {
    background-color: $purple;
    opacity: 1;
    color: #fff;

    i {
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    color: #fff;
    background: $purple;
  }
}
</style>
