<template>
  <el-dialog
    :visible.sync="dialogMessageVisible"
    width="60%"
    append-to-body
    @open="getMessageList"
  >
    <div class="titleWrapper">
      <div class="tabsWrapper">
        <el-badge
          is-dot
          :value="count1"
          :hidden="count1 < 1"
          :class="{ 'is-active': params.type == 1 }"
          @click.native="params.type = 1"
        >
          <span class="el-notice-desc">系统通知</span>
        </el-badge>
        <el-badge
          is-dot
          :value="count2"
          :hidden="count2 < 1"
          :class="{ 'is-active': params.type == 2 }"
          @click.native="params.type = 2"
        >
          <span class="el-notice-desc">联合通知</span>
        </el-badge>
      </div>
      <div class="selectWrapper">
        状态
        <el-select v-model="params.isRead" :placeholder="options.list[0].label">
          <el-option
            v-for="item in options.list"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="contentWrapper">
      <div class="title">
        <p class="date">通知时间</p>
        <p class="content">通知详情</p>
      </div>
      <div class="detailWrapper">
        <template v-if="messageList.length > 0">
          <div class="detail" v-for="(item, index) in messageList" :key="index">
            <p class="date">{{ item.noticeTime }}</p>
            <p class="content">
              {{ item.noticeContent }}
              <el-badge is-dot :hidden="item.isRead == 1">
                <button
                  @click="
                    (messageDetail = item),
                      (dialogMessageContentVisible = true),
                      changeMessageStatus(item)
                  "
                >
                  点击查看
                </button>
              </el-badge>
            </p>
            <p class="status">
              <span v-if="item.isRead == 1">已读</span>
              <span v-else>未读</span>
            </p>
          </div>
        </template>
        <template v-else>
          <div class="noData">
            <p>暂无数据</p>
          </div>
        </template>
      </div>
    </div>
    <message-content-dialog-component
      :status.sync="dialogMessageContentVisible"
      :data="messageDetail"
    />
  </el-dialog>
</template>

<script>
// import common from '@/util/common';
import messageContentDialogComponent from './messageContentDialogComponent';
import { getSystemNoticeList, saveNoticeStatus } from '@/api/b/common.api';
// import _ from 'lodash';

export default {
  name: 'messageDialogComponent',
  components: {
    messageContentDialogComponent,
  },
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialogMessageVisible: {
      get() {
        return this.status;
      },
      set(val) {
        this.$emit('update:status', val);
      },
    },
  },
  watch: {
    params: {
      handler() {
        this.getMessageList();
      },
      deep: true,
    },
  },
  data() {
    return {
      activeName: 'first',
      messageDetail: {},
      messageList: [],
      options: {
        list: [
          {
            value: '',
            label: '全部',
          },
          {
            value: 0,
            label: '未读',
          },
          {
            value: 1,
            label: '已读',
          },
        ],
        model: '',
      },
      count1: 0,
      count2: 0,
      params: {
        type: 1,
        isRead: '',
        pageNo: 1,
        pageSize: 10,
      },
      dialogMessageContentVisible: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:status', false);
    },
    handleClick(tab) {
      tab;
    },
    getMessageList() {
      const params = this.params;
      getSystemNoticeList(
        params.type,
        params.isRead,
        params.pageNo,
        params.pageSize,
      ).then((response) => {
        if (response.code == 200) {
          this.messageList = response.data.list;
        }
      });
    },
    changeMessageStatus(item) {
      const id = item.id;
      saveNoticeStatus(id).then((response) => {
        if (response.code == 200) {
          item.isRead = 1;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: vw(70) 0 0;
  text-align: center;
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .el-badge {
    position: relative;
    margin-right: vw(30);
    cursor: pointer;
    border: 1px solid $darkgray;
    padding: vw(20) vw(20) vw(20) vw(80);
    border-radius: vw(24);
    font-size: vw(34);

    &:nth-of-type(1) {
      background-image: url('https://img10.360buyimg.com/imgzone/jfs/t1/201232/18/26595/548/63dd0d38F6712bc32/5ed2e529408cbe12.png');
      background-repeat: no-repeat;
      background-size: vw(38);
      background-position: 15% 50%;
    }

    &:nth-of-type(2) {
      background-image: url('https://img10.360buyimg.com/imgzone/jfs/t1/194849/10/32485/537/63dd0d37F0581c23b/b97878694bde06d2.png');
      background-repeat: no-repeat;
      background-size: vw(38);
      background-position: 15% 50%;
    }

    &.is-active {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: vw(-16);
        width: 100%;
        height: vw(8);
        border-radius: vw(4);
        background: $purple;
      }
    }

    ::v-deep .el-badge__content.is-dot {
      width: vw(16);
      height: vw(16);
      background: red;

      &.is-fixed {
        right: 0;
        transform: translateX(50%);
      }
    }

    .el-notice-desc {
      font-size: vw(34);
      color: $darkgray;
    }
  }

  .selectWrapper {
    font-size: vw(28);
    color: $darkgray;
  }

  ::v-deep .el-input {
    font-size: vw(28);
    width: vw(170);

    .el-input__inner {
      height: auto;
      line-height: 1;
      padding: vw(20) vw(60) vw(20) vw(20);
    }
  }

  ::v-deep .el-select .el-input .el-select__caret {
    font-size: vw(28);
    width: vw(60);
    line-height: 1;
  }
}

.el-select-dropdown__item {
  font-size: vw(28);
  padding: vw(20);
  height: auto;
  line-height: 1;
}

.contentWrapper {
  margin-top: vw(80);

  .title {
    display: flex;
    font-size: vw(28);
    margin-bottom: vw(15);

    .date {
      width: 15%;
      color: $darkgray;
      text-align: center;
    }

    .content {
      width: 75%;
      color: $darkgray;
      padding-left: vw(100);
    }

    .status {
      width: 10%;
    }
  }
}

.detailWrapper {
  background: $lightgray;
  min-height: vw(700);
  padding: vw(12);

  .detail {
    display: flex;
    align-items: center;
    font-size: vw(28);
    border-radius: vw(24);
    background: #fff;
    border: 1px solid $darkgray;
    margin-bottom: vw(20);
    padding: vw(20) 0;

    .date {
      width: 15%;
      color: $darkgray;
      text-align: center;
    }

    .content {
      width: 75%;
      color: $darkgray;
      padding-left: vw(50);
      display: flex;
      align-items: center;

      .el-badge {
        margin-left: vw(15);

        button {
          font-size: vw(28);
          color: $purple;
        }

        ::v-deep .el-badge__content {
          font-size: vw(28);
          width: vw(16);
          height: vw(16);
          right: vw(-10);
          top: vw(-6);
          transform: none;
        }
      }
    }

    .status {
      width: 10%;
      color: #52a8ff;
      text-align: center;
    }
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: vw(700);

  p {
    font-size: vw(40);
    color: $darkgray;
  }
}
</style>
