<template>
  <el-dialog :visible.sync="dialogUserInfoVisible" width="50%" append-to-body>
    <div class="shopId">
      <p class="title">您的会员编号：</p>
      <p>{{ userInfo.shopId }}</p>
    </div>
    <div class="type">
      <p class="title">品牌种类：</p>
      <p>{{ userInfo.mainOneClassName }}</p>
    </div>
    <div class="detailWrapper">
      <div class="detail">
        <p class="title">1.店铺名称</p>
        <p>{{ userInfo.shopName }}</p>
      </div>
      <div class="detail">
        <p class="title">2.LOGO</p>
        <el-avatar :src="userInfo.shopLogo"></el-avatar>
      </div>
      <div class="detail">
        <p class="title">3.店铺ID</p>
        <p>{{ userInfo.shopId }}</p>
      </div>
      <div class="detail">
        <p class="title">4.店铺链接</p>
        <el-input v-model="userInfo.shopUrl" placeholder></el-input>
      </div>
      <div class="detail">
        <p class="title">5.商家地址</p>
        <el-input v-model="userInfo.shopAddress" placeholder></el-input>
      </div>
      <div class="detail">
        <p class="title">6.商家电话</p>
        <el-input v-model="userInfo.phone" placeholder></el-input>
      </div>
      <div class="detail">
        <p class="title">7.客服电话</p>
        <el-input v-model="userInfo.csPhone" placeholder></el-input>
      </div>
      <div class="detail">
        <p class="title">8.客服微信</p>
        <el-input v-model="userInfo.csVx" placeholder></el-input>
      </div>
      <div class="detail">
        <p class="title">9.客服QQ</p>
        <el-input v-model="userInfo.csQq" placeholder></el-input>
      </div>
    </div>
    <div class="buttonWrapper">
      <button class="cancle" @click="handleClose">取消</button>
      <button class="save" @click="updateBasicUserInfoFunc">保存</button>
    </div>
  </el-dialog>
</template>

<script>
import common from '@/util/common';
import _ from 'lodash';
// import { mapGetters } from 'vuex';
import { updateBasicUserInfo, uploadFileByIO } from '@/api/b/common.api';

export default {
  name: 'userInfoDialogComponent',
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialogUserInfoVisible: {
      get() {
        return this.status;
      },
      set(val) {
        this.$emit('update:status', val);
      },
    },
  },
  data() {
    return {
      options1: [],
      value1: '',
      isUploading: false,
      userInfo: {},
    };
  },
  created() {
    this.userInfo =
      common.getStoreData('shopInfo') ?? this.$store.state.access.userInfo;
  },
  methods: {
    handleClose() {
      this.userInfo =
        common.getStoreData('shopInfo') ?? this.$store.state.access.userInfo;
      this.$emit('update:status', false);
    },
    handleAvatarSuccess() {},
    beforeUploadFunc() {
      this.isUploading = true;
    },
    uploadFileByIOFunc(target) {
      const file = target.file;
      let formData = new FormData();
      formData.append('file', file);
      uploadFileByIO(formData).then((response) => {
        if (response.code == 200) {
          this.isUploading = false;
          this.params.fileUrl = response.data;
        }
      });
    },
    updateBasicUserInfoFunc() {
      const params = {
        shopAddress: this.userInfo.shopAddress,
        phone: this.userInfo.phone,
        csPhone: this.userInfo.csPhone,
        csVx: this.userInfo.csVx,
        csQq: this.userInfo.csQq,
        vcVenderCode: this.$store.state.user.shopInfo.vcVenderCode,
      };

      let formData = new FormData();
      _.toPairs(params).forEach((item) => {
        formData.append(item[0], item[1]);
      });
      updateBasicUserInfo(formData).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '修改成功！',
            type: 'success',
            duration: 2000,
          });
          this.$store.dispatch('user/getShopInfo');
          this.dialogUserInfoVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: vw(50) 0 0;
}
::v-deep .el-dialog__body {
  padding: 0 vw(50) vw(50);
  color: $darkgray;
}

.shopId {
  display: flex;
  align-items: center;
  font-size: vw(28);

  .title {
    width: 15%;
  }
}

.type {
  display: flex;
  align-items: center;
  margin-top: vw(30);
  font-size: vw(28);

  .title {
    width: 15%;
  }
}

.detailWrapper {
  margin-top: vw(30);

  .detail {
    display: flex;
    align-items: center;
    margin-bottom: vw(30);
    font-size: vw(28);

    .title {
      width: 15%;
    }

    .el-input {
      width: 55%;
      font-size: vw(28);

      ::v-deep .el-input__inner {
        height: auto;
        line-height: 1;
        padding: vw(20);
        border: 1px solid $darkgray;
      }
    }

    .el-avatar {
      width: vw(80);
      height: vw(80);
      display: block;
    }
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: vw(60);

  button {
    font-size: vw(32);
    margin: 0 vw(20);
    padding: vw(10) vw(40);
    border-radius: vw(24);
  }

  .cancle {
    border: 1px solid $darkgray;
  }

  .save {
    background: $purple;
    color: #fff;
  }
}
</style>
