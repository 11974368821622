<template>
  <div class="top-right-btn">
    <el-row>
      <template v-for="item in visibleBtns">
        <el-tooltip
          class="item"
          effect="dark"
          :content="showSearch ? '隐藏搜索' : '显示搜索'"
          placement="top"
          v-if="item.indexOf('search') > -1"
          :key="item"
        >
          <el-button
            size="mini"
            circle
            icon="el-icon-search"
            @click="toggleSearch()"
          />
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="刷新"
          placement="top"
          v-if="item.indexOf('refresh') > -1"
          :key="item"
        >
          <el-button
            size="mini"
            circle
            icon="el-icon-refresh"
            @click="refresh()"
          />
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="显隐列"
          placement="top"
          v-if="columns && item.indexOf('columns') > -1"
          :key="item"
        >
          <el-button
            size="mini"
            circle
            icon="el-icon-menu"
            @click="showColumn()"
          />
        </el-tooltip>
      </template>
    </el-row>
    <el-dialog
      :title="title"
      :visible.sync="open"
      append-to-body
      :before-close="handleClose"
    >
      <el-transfer
        :titles="['显示', '隐藏']"
        v-model="value"
        :data="columns"
        @change="dataChange"
      ></el-transfer>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'RightToolbar',
  data() {
    return {
      // 显隐数据
      value: [],
      // 弹出层标题
      title: '显示/隐藏',
      // 是否显示弹出层
      open: false,
    };
  },
  props: {
    showSearch: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
    },
    visibleBtns: {
      type: Array,
      default: () => ['search', 'refresh', 'columns'],
    },
  },
  created() {
    // 显隐列初始默认隐藏列
    for (let item in this.columns) {
      if (this.columns[item].visible === false) {
        this.value.push(parseInt(item));
      }
    }
  },
  methods: {
    // 搜索
    toggleSearch() {
      this.$emit('update:showSearch', !this.showSearch);
    },
    // 刷新
    refresh() {
      this.$emit('queryTable');
    },
    // 右侧列表元素变化
    dataChange(data) {
      for (var item in this.columns) {
        const key = this.columns[item].key;
        this.columns[item].visible = !data.includes(key);
      }
    },
    // 打开显隐列dialog
    showColumn() {
      this.open = true;
    },
    //弹出框关闭的回调
    handleClose(done) {
      this.$emit('columnsChange');
      done();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-transfer__button {
  border-radius: 50%;
  padding: 12px;
  display: block;
  margin-left: 0px;
}
::v-deep .el-transfer__button:first-child {
  margin-bottom: 10px;
}
</style>
