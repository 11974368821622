import Vue from 'vue';
import Vuex from 'vuex';

import access from './modules/access.module';
import common from './modules/common.module';
import data from './modules/data.module';
import unionShops from './modules/unionShops.module';
import user from './modules/user.module';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  modules: {
    access: {
      namespaced: true,
      ...access,
    },
    common: {
      namespaced: true,
      ...common,
    },
    data: {
      namespaced: true,
      ...data,
    },
    unionShops: {
      namespaced: true,
      ...unionShops,
    },
    user: {
      namespaced: true,
      ...user,
    },
  },
});

export default store;
