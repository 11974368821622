const state = {
  activeIndex: 0,
  templateList: {},
  activeMenu: '',
  menuName: '',
  mainMenuName: '',
  wait: 0,
};

const getters = {
  activeIndex: (state) => state.activeIndex,
  templateList: (state) => state.templateList,
  activeMenu: (state) => state.activeMenu,
  menuName: (state) => state.menuName,
  mainMenuName: (state) => state.mainMenuName,
  wait: (state) => state.wait,
};

const mutations = {
  handleChangeActiveIndex(state, index) {
    state.activeIndex = index;
  },
  handleSetTemplateList(state, info) {
    state.templateList = info;
  },
  handleChangeActiveMenu(state, info) {
    state.activeMenu = info;
  },
  handleChangeMenuName(state, info) {
    state.menuName = info;
  },
  handleChangeMainMenuName(state, info) {
    state.mainMenuName = info;
  },
  handleChangeWait(state, status) {
    state.wait = status;
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
