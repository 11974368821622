import request from '@/util/http';
import { common } from '../urls/common';

// 保存菜单访问日志
export function saveLog(params) {
  return request.POST(common.saveLog, params);
}

// 获取消息模板
export function getSystemNoticeList(type, isRead, pageNo, pageSize) {
  return request.GET(
    common.getSystemNoticeList +
      '?type=' +
      type +
      '&isRead=' +
      isRead +
      '&pageNo=' +
      pageNo +
      '&pageSize=' +
      pageSize,
  );
}

// 商家点击通知接口
export function saveNoticeStatus(id) {
  return request.GET(common.saveNoticeStatus + '?noticeId=' + id);
}

// 保存反馈信息
export function saveFeedback(params) {
  return request.POST(common.saveFeedback, params);
}

// 上传图片（文件流）
export function uploadFileByIO(params) {
  return request.POST(common.uploadFileByIO, params);
}

// 一键补齐资料
export function updateBasicUserInfo(params) {
  return request.POST(common.updateBasicUserInfo, params);
}

// 创建-直发型优惠券(POP)
export function createPopCoupon(params) {
  return request.POST(common.createPopCoupon, params);
}

// 创建-直发型优惠券(自营）
export function createSoCoupon(params) {
  return request.POST(common.createSoCoupon, params);
}

// 优惠券绑定活动
export function couponBindActivity(params) {
  return request.POST(common.couponBindActivity, params);
}
