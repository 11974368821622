<template>
  <el-aside>
    <el-menu
      router
      popper-append-to-body="true"
      text-color="#eeeeff"
      :default-active="activeMenu?.menuLink"
    >
      <!--将子菜单抽取出来，做为一个子组件-->
      <side-nav-menu-item-component
        v-for="item in menus[activeIndex]?.children"
        :key="item.menuName"
        :data="item"
      />
    </el-menu>
  </el-aside>
</template>

<script>
import sideNavMenuItemComponent from './sideNavMenuItemComponent';
import common from '@/util/common';
import { mapGetters } from 'vuex';

export default {
  name: 'sideNavComponent',
  components: {
    sideNavMenuItemComponent,
  },
  computed: {
    ...mapGetters('data', ['activeIndex', 'activeMenu']),
    // ...mapGetters('access', ['menuPermissions']),
    // ...mapGetters('common', ['menus']),
    menus() {
      return (
        common.getStoreData('menuPermissions') ?? this.$store.state.common.menus
      );
    },
  },
  watch: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-aside {
  position: fixed;
  top: 0;
  left: 0;
  width: nvw(300) !important;
  height: 100%;
  background: $blueblack;
  padding-top: nvw(150);
  z-index: 9;
}

.el-menu {
  background: transparent;
  border-right: none;
}
</style>
