<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex';
import common from '@/util/common';
import { signIn } from '@/api/b/login.api';
import { getShopInfo, getVaildList } from '@/api/b/shopInfo.api';
// import { getAccountInfo } from '@/api/b/manage.api';
import _ from 'lodash';

export default {
  name: 'login',
  data() {
    return {
      // venderId: '1000100710',
      // venderId: '12743066',
      venderId: '',
      prodBToken: '',
      menus: [],
    };
  },
  created() {
    // 正式版内容
    this.getRouterQuery();
    common.clearStoreData();
    this.loginSubmit();
  },
  methods: {
    ...mapActions('access', ['login']),
    ...mapActions('common', ['setMenus']),

    loginSubmit() {
      // 正式版内容
      if (!this.venderId || !this.prodBToken) {
        this.$router.push('loginTimeOut');
        return;
      }
      const _this = this;

      // signIn(this.venderId)
      //   .then((response) => {
      //     if (response.code == 200) {
      //       this.prodBToken = response.data;
      //       common.setStoreData('prodBToken', this.prodBToken);
      //     }
      //   })
      //   .then(() => {
      //     getVaildList()
      //       .then((response) => {
      //         if (response.code == 200) {
      //           _(response.data)
      //             .groupBy('mainMenuName')
      //             .toPairs()
      //             .value()
      //             .forEach((item, index) => {
      //               let object = {};
      //               _.setWith(object, '[title]', item[0]);
      //               _.setWith(object, '[children]', item[1]);
      //               _.setWith(object, '[index]', index);
      //               _.setWith(object, '[id]', index + 1);
      //               _.setWith(object, '[menuLink]', item[1][0].menuLink);
      //               _this.menus.push(object);
      //             });
      //           this.setMenus(this.menus);
      //           // let state = {
      //           //   menuPermissions: this.$store.state.common.menus,
      //           // };
      //           // this.login(state);
      //           common.setStoreData('menuPermissions', this.menus);
      //           common.setStoreData('activeIndex', 0);
      //         }
      //       })
      //       .then(() => {
      //         getShopInfo().then((response) => {
      //           if (response.code == 200) {
      //             const state = {
      //               userInfo: response.data,
      //             };
      //             this.login(state);
      //             this.$store.commit('user/changeShopInfo', response.data);
      //             common.setStoreData('shopInfo', response.data);
      //             this.$router.push({ name: 'home' });
      //           }
      //         });
      //       });
      //   });

      // 正式版内容
      getVaildList()
        .then((response) => {
          if (response?.code == 200) {
            _(response.data)
              .groupBy('mainMenuName')
              .toPairs()
              .value()
              .forEach((item, index) => {
                let object = {};
                _.setWith(object, '[title]', item[0]);
                _.setWith(object, '[children]', item[1]);
                _.setWith(object, '[index]', index);
                _.setWith(object, '[id]', index + 1);
                _.setWith(object, '[menuLink]', item[1][0].menuLink);
                _this.menus.push(object);
              });
            this.setMenus(this.menus);
            // let state = {
            //   menuPermissions: this.$store.state.common.menus,
            // };
            // this.login(state);
            common.setStoreData('menuPermissions', this.menus);
            common.setStoreData('activeIndex', 0);
          }
        })
        .then(() => {
          getShopInfo().then((response) => {
            if (response?.code == 200) {
              const state = {
                userInfo: response.data,
              };
              this.login(state);
              common.setStoreData('shopInfo', response.data);
              this.$router.push({ name: 'home' });
            }
          });
        });
    },
    filterPermissions(menuPermissions) {
      return menuPermissions.filter((item) => {
        if (item.children?.length > 0) {
          item.children = this.filterPermissions(item.children);
        }
        return item.entryType === 0;
      });
    },

    // 获取路由参数
    getRouterQuery() {
      const routerQueryInfo = this.$route.query;
      this.venderId = routerQueryInfo?.venderId;
      this.prodBToken = routerQueryInfo?.token;
      common.setStoreData('prodBToken', this.prodBToken);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  .login-error {
    margin-top: 150px;
    text-align: center;
    .icon {
      display: inline-block;
      width: 462px;
      height: 258px;
    }

    .description {
      margin: 30px 0 40px;
      font-size: 26px;
      font-weight: 600;
    }

    .btn {
      background-color: #8080ff;
      color: #fff;
      font-size: 18px;
      border-radius: 12px;
    }
  }
}
</style>
