<template>
  <el-dialog
    :visible.sync="dialogMessageContentVisible"
    width="50%"
    append-to-body
  >
    <div class="titleWrapper">
      <p class="date">{{ data.noticeTime }}</p>
      <p class="title">{{ data.noticeContent }}</p>
    </div>
    <div class="contentWrapper">
      <p>{{ data.noticeContent }}</p>
    </div>
  </el-dialog>
</template>

<script>
// import common from '@/util/common';
// import _ from 'lodash';

export default {
  name: 'messageContentDialogComponent',
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogMessageContentVisible: {
      get() {
        return this.status;
      },
      set(val) {
        this.$emit('update:status', val);
      },
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit('update:status', false);
    },
    handleClick(tab) {
      tab;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  padding: vw(70) 0 0;
  text-align: center;
}

::v-deep .el-dialog__body {
  padding: vw(60) vw(50) vw(90);
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: vw(30) vw(70);
  background: $lightgray;
  border-radius: vw(50);
  font-size: vw(32);

  .date {
    width: 20%;
    color: $darkgray;
  }

  .title {
    width: 80%;
    text-align: right;
    color: $darkgray;
  }
}

.contentWrapper {
  padding: vw(50) vw(20) vw(50) vw(50);
  border: 1px solid $darkgray;
  border-radius: vw(24);
  height: vw(450);
  margin-top: vw(60);
  overflow: hidden;

  p {
    color: $darkgray;
    font-size: vw(24);
    height: 100%;
    overflow-y: auto;
    padding-right: vw(30);
  }
}
</style>
