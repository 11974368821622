<template>
  <el-dialog :visible.sync="dialogConsultVisible" width="60%" append-to-body>
    <div>
      <h3 class="title">
        请选择问题类型对应问题添加客服
        <br />
        以便更好为您服务
      </h3>
      <div class="detailWrapper">
        <div class="detail">
          <div>
            <h3>模板互动</h3>
            <p>为用户解答互动创建中遇到的问题</p>
          </div>
          <div>
            <p>微信群：XXXXXXXXXXX</p>
            <p>QQ群：XXXXXXXXXXX</p>
          </div>
        </div>
        <div class="detail">
          <div>
            <h3>专属定制</h3>
            <p>以用户期望需求，提供策划，设计，研发，报价等</p>
          </div>
          <div>
            <p>微信：XXXXXXXXXXX</p>
          </div>
        </div>
        <div class="detail">
          <div>
            <h3>充值问题</h3>
            <p>为用户解决充值过程中发生的问题</p>
          </div>
          <div>
            <p>微信群：XXXXXXXXXXX</p>
            <p>QQ群：XXXXXXXXXXX</p>
          </div>
        </div>
      </div>
      <div class="tel">客服电话：XXXXXXXXXXX</div>
    </div>
  </el-dialog>
</template>

<script>
// import common from '@/util/common';

export default {
  name: 'consultDialogComponent',
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialogConsultVisible: {
      get() {
        return this.status;
      },
      set(val) {
        this.$emit('update:status', val);
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    handleClose() {
      this.$emit('update:status', false);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  text-align: center;
  padding: vw(150) 0 0;
  background-image: url('https://img10.360buyimg.com/imgzone/jfs/t1/87160/16/32259/4903/63dc9881F5b3d5a87/53c8d919310f6cad.png');
  background-size: vw(640);
  background-repeat: no-repeat;
  background-position: 90%;
}

::v-deep .el-dialog__body {
  padding: 0 vw(60) vw(100);
  margin-top: vw(-35);

  .title {
    font-size: vw(40);
    text-align: center;
    color: $darkgray;
  }

  .detailWrapper {
    padding: vw(20) vw(40);
    border-radius: vw(24);
    border: 1px solid $darkgray;
    margin: vw(30) 0;

    .detail {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:first-of-type) {
        margin-top: vw(50);
      }

      h3 {
        color: $darkgray;
        font-size: vw(36);
      }

      p {
        color: $darkgray;
        font-size: vw(24);
      }

      > div:last-of-type {
        width: vw(400);
        height: vw(160);
        background: $lightgray;
        border-radius: vw(24);
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: vw(60);

        p {
          margin: vw(10) 0;
        }
      }
    }
  }

  .tel {
    font-size: vw(45);
    color: #fff;
    background: $purple;
    border-radius: vw(24);
    text-align: center;
    padding: vw(40) 0;
  }
}
</style>
