<template>
  <el-dialog :visible.sync="dialogFeedbackVisible" width="60%" append-to-body>
    <div class="typeWrapper">
      <p class="title">反馈类型</p>
      <el-radio-group v-model="params.type">
        <el-radio :label="1">新增功能建议</el-radio>
        <el-radio :label="2">优化建议</el-radio>
        <el-radio :label="3">BUG反馈</el-radio>
      </el-radio-group>
    </div>
    <div class="contentWrapper">
      <p class="title">反馈内容</p>
      <el-input type="textarea" v-model="params.content" :rows="4"></el-input>
      <el-upload
        class="upload-demo"
        action
        :http-request="uploadFileByIOFunc"
        :before-upload="beforeUploadFunc"
        :on-remove="handleRemove"
        :limit="1"
        :file-list="fileList"
      >
        <el-button size="small" type="primary">
          <img
            src="https://img10.360buyimg.com/imgzone/jfs/t1/36231/17/19936/331/63dce0daFd6e431d5/2153c076a44d016c.png"
            alt
          />
          <span v-if="!isUploading">上传附件</span>
          <span v-else>正在上传</span>
        </el-button>
        <div slot="tip" class="el-upload__tip">
          上传附件支持.jpg .png .rar .doc .xls格式，最大不超过5M
        </div>
      </el-upload>
    </div>
    <div class="phoneWrapper">
      <p class="title">
        联系手机
        <span>&nbsp;&nbsp;反馈的处理动态，将通过短信发送到您的手机</span>
      </p>
      <el-input v-model="params.phone" placeholder="请输入手机号码"></el-input>
    </div>
    <div class="buttonWrapper">
      <button @click="saveFeedbackFunc">我要反馈</button>
    </div>
  </el-dialog>
</template>

<script>
import common from '@/util/common';
import { saveFeedback, uploadFileByIO } from '@/api/b/common.api';
import _ from 'lodash';

export default {
  name: 'feedbackDialogComponent',
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialogFeedbackVisible: {
      get() {
        return this.status;
      },
      set(val) {
        this.isUploading = false;
        this.params = {
          venderId: '',
          shopName: '',
          type: '',
          content: '',
          phone: '',
          fileUrl: '',
        };
        this.fileList = [];
        this.$emit('update:status', val);
      },
    },
  },
  data() {
    return {
      isUploading: false,
      fileList: [],
      params: {
        venderId: '',
        shopName: '',
        type: '',
        content: '',
        phone: '',
        fileUrl: '',
      },
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.$emit('update:status', false);
    },
    handleRemove() {
      this.fileList = [];
      this.params.fileUrl = '';
    },
    beforeUploadFunc() {
      this.isUploading = true;
    },
    saveFeedbackFunc() {
      const userInfo = common.getStoreData('shopInfo');
      this.params.venderId = userInfo.shopId;
      this.params.shopName = userInfo.shopName;
      let formData = new FormData();
      _.toPairs(this.params).forEach((item) => {
        formData.append(item[0], item[1]);
      });
      saveFeedback(this.params).then((response) => {
        if (response.code == 200) {
          console.log('response: ', response.data);
        }
      });
    },
    uploadFileByIOFunc(target) {
      const file = target.file;
      let formData = new FormData();
      formData.append('file', file);
      uploadFileByIO(formData).then((response) => {
        if (response.code == 200) {
          this.isUploading = false;
          this.params.fileUrl = response.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  text-align: center;
  padding: vw(70) 0 0;
}

::v-deep .el-dialog__body {
  padding: 0 vw(70) vw(70);
}

.typeWrapper {
  .el-radio-group {
    margin-top: vw(60);
    display: flex;
  }

  ::v-deep .el-radio {
    color: $darkgray;
    margin: 0 vw(40);
    font-size: vw(28);
    display: flex;
    align-items: center;
  }

  ::v-deep .el-radio__label {
    // padding-left: vw(20);
    font-size: vw(28);

    width: vw(250);
    padding: vw(20) 0;
    border-radius: vw(24);
    border: 1px solid $darkgray;
    text-align: center;
  }

  ::v-deep .el-radio__input {
    color: $purple;
    display: none;
  }

  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    background: $purple;
    color: #fff;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: $purple;
    background: $purple;
  }

  ::v-deep .el-radio__inner {
    width: vw(28);
    height: vw(28);
  }

  ::v-deep .el-radio__inner::after {
    width: vw(8);
    height: vw(8);
  }
}

.contentWrapper {
  margin-top: vw(80);

  .el-textarea {
    margin-top: vw(60);
  }

  .upload-demo {
    display: flex;
    align-items: center;
    margin-top: vw(20);
    flex-wrap: wrap;

    .el-upload__tip {
      font-size: vw(28);
      margin-top: 0;
      margin-left: vw(22);
      color: $red;
    }

    ::v-deep .el-upload-list {
      width: 50%;
    }

    ::v-deep .el-upload-list__item {
      font-size: vw(40);
    }
  }

  ::v-deep .el-textarea__inner {
    border: 1px solid $darkgray;
    font-size: vw(28);
    padding: vw(20);
  }

  ::v-deep .el-upload {
    .el-button {
      font-size: vw(28);
      border-radius: vw(30);
      background: $purple;
      border: none;
      padding: vw(14) vw(30);

      > span {
        display: flex;

        img {
          width: vw(26);
          margin-right: vw(6);
        }
      }
    }
  }
}

.phoneWrapper {
  margin-top: vw(80);

  .title {
    span {
      color: $red;
    }
  }

  ::v-deep .el-input {
    font-size: vw(28);
    margin-top: vw(60);
    width: auto;

    .el-input__inner {
      height: auto;
      line-height: 1;
      font-size: vw(28);
      padding: vw(20);
      color: $darkgray;
      border: 1px solid $darkgray;
    }
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: vw(90);

  button {
    font-size: vw(40);
    background: $purple;
    color: #fff;
    padding: vw(20) vw(100);
    border-radius: vw(24);
  }
}

.title {
  position: relative;
  font-size: vw(28);
  color: $darkgray;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: vw(-30);
    width: vw(12);
    height: vw(12);
    border-radius: 50%;
    background: $darkgray;
    transform: translateY(-50%);
  }
}
</style>
