import common from '@/util/common';
import { getAccountInfo } from '@/api/b/manage.api';
import { getShopInfo } from '@/api/b/shopInfo.api';

const state = {
  shopInfo: {},
  jdBeansInfo: {},
};

const getters = {
  shopInfo: (state) => state.shopInfo,
  jdBeansInfo: (state) => state.jdBeansInfo,
};

const mutations = {
  changeJdBeansInfo(state, data) {
    state.jdBeansInfo = data;
  },
  changeShopInfo(state, data) {
    state.shopInfo = data;
  },
};

const actions = {
  async getBeansInfo({ commit }) {
    const res = await getAccountInfo();
    if (res.code === '200') {
      commit('changeJdBeansInfo', res.data);
    }
  },

  async getShopInfo({ commit }) {
    const res = await getShopInfo();
    if (res.code === '200') {
      common.setStoreData('shopInfo', res.data);
      console.log('值回啦了');
      commit('changeShopInfo', res.data);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
