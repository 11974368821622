const state = {
  reviewDialogVisible: false, // 查看公交车弹窗
  qrCodeDialogVisible: false, // 查看公交二维码弹窗
  waitDetailDialogVisible: false, // 等车太阳伞详细资料弹窗
  basicDialogVisible: false, // 等车普通用户详细资料弹窗
  prizeDialogVisible: false, // 选择奖品弹窗
  prizePopDialogVisible: false, // 选择奖品POP弹窗

  activityId: undefined, // 当前选择 Bus Id
  activeBusInfo: undefined, // 当前选中公交详情
  basicSelectVenderInfoVenderId: undefined, // 点击无伞查询用户详细资料
  waitSelectVenderInfoVenderId: undefined, // 点击太阳伞查询用户详细资料
};

const getters = {
  reviewDialogVisible: (state) => state.reviewDialogVisible,
  qrCodeDialogVisible: (state) => state.qrCodeDialogVisible,
  waitDetailDialogVisible: (state) => state.waitDetailDialogVisible,
  basicDialogVisible: (state) => state.basicDialogVisible,
  prizeDialogVisible: (state) => state.prizeDialogVisible,
  prizePopDialogVisible: (state) => state.prizePopDialogVisible,

  activityId: (state) => state.activityId,
  activeBusInfo: (state) => state.activeBusInfo,
  basicSelectVenderInfoVenderId: (state) => state.basicSelectVenderInfoVenderId,
  waitSelectVenderInfoVenderId: (state) => state.waitSelectVenderInfoVenderId,
};

const mutations = {
  // 查看公交车弹窗状态改变
  setReviewDialogVisible(state, status) {
    state.reviewDialogVisible = status;
  },
  // 查看公交二维码弹窗状态改变
  setQRCodeDialogVisible(state, status) {
    state.qrCodeDialogVisible = status;
  },
  // 等车太阳伞详细资料弹窗状态改变
  setWaitDetailDialogVisible(state, status) {
    state.waitDetailDialogVisible = status;
  },
  // 等车普通用户详细资料弹窗状态改变
  setBasicDialogVisible(state, status) {
    state.basicDialogVisible = status;
  },
  // 选择奖品弹窗状态改变
  setPrizeDialogVisible(state, status) {
    state.prizeDialogVisible = status;
  },
  // 选择奖品POP弹窗状态改变
  setPrizePopDialogVisible(state, status) {
    state.prizePopDialogVisible = status;
  },

  // 当前选中公交id改变
  setActivityId(state, id) {
    state.activityId = id;
  },

  // 当前选中公交详情
  setActiveBusInfo(state, info) {
    state.activeBusInfo = info;
  },

  // 点击查询用户详细资料
  setBasicSelectVenderInfoVenderId(state, venderId) {
    state.basicSelectVenderInfoVenderId = venderId;
  },
  setWaitSelectVenderInfoVenderId(state, venderId) {
    state.waitSelectVenderInfoVenderId = venderId;
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
